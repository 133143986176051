import $ from 'jquery';
import React, {Fragment} from 'react';
import I18n from 'app/i18n';
import {gameName} from '../pages/esport/esport';
import {clear_hash, date, flagImage, getGame, time, timeStampToISODateTimeFormat} from 'app/utils';
import FormCover from './form_cover';
import Rows from './rows';
import {lineFullNick, lineScores, isOpened} from 'app/lines/line_helpers';
import {DRAW, STATUS_CANCEL_COMPLETED, STATUS_COMPLETED} from 'admin/components/bet_form/utils/LineConsts';
import GamerPhoto from 'app/components/bet_form/GamerPhoto';
import {hasNestedLines} from 'app/lines/hasNestedLines';
import {isAdvantageLine} from 'app/lines/isAdvantageLine';
import {lineGamer} from 'app/lines/lineGamer';

const GAMER_1 = 1,
    GAMER_2 = 2;

export default class Form extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            formIdShow: props.formIdShow,
            gamerNum: props.gamerNum,
            idBetShow: props.idBetShow,
            isHidden: props.formIdShow !== Form.betId(this.props.bet),
            selectedBet: props.selectedBet,
            userBetsHidden: true
        };
    }

    static betId(bet) {
        return bet.hash_id || bet.id;
    }

    componentDidMount() {
        if (!this.props.seo) {
            location.hash = Form.betId(this.props.bet);
        }
    }

    componentWillUnmount() {
        clear_hash();
    }

    renderGamer(num) {
        const gamer = lineGamer(this.props.bet, num);
        return <div className="match-details__vs-player1">
            <div className="match-details__vs-player1-title">
                {flagImage(gamer.flag)} {lineFullNick(this.props.bet, num)}
            </div>
            <div className="match-details__vs-player1-image">{this.renderPhoto(num)}</div>
        </div>;
    }

    renderPhoto = num => <GamerPhoto line_gamer={lineGamer(this.props.bet, num)}/>;

    renderScores() {
        let {bet} = this.props;
        const lines = this.bets();
        lines.forEach(line => {
            if (this.state.formIdShow === Form.betId(line)) {
                bet = line;
            }
        });
        const scores = <div className="match-details__vs-score">{lineScores(bet)}</div>;

        if (STATUS_COMPLETED === this.props.bet.status) {
            return scores;
        }

        if (STATUS_CANCEL_COMPLETED === this.props.bet.status && DRAW === this.props.bet.winner) {
            return <Fragment>
                {scores}
                <span>{I18n.t('draw_label')}</span>
            </Fragment>;
        }

        return null;
    }

    bets(onlyIds = false) {
        const bets = this.props.bet.nested_bets.reduce(
            (prev, nested_bet) => [...prev, nested_bet],
            [this.props.bet]
        );
        return onlyIds ? bets.map(bet => bet.id) : bets;
    }

    isChildBetOfSelected = (bet, parent_bet) => !(this.props.formIdShow === Form.betId(this.props.bet)) &&
        (this.state.idBetShow === bet.game_id || parent_bet && this.state.idBetShow === parent_bet.game_id);

    renderForms() {
        const lines = this.bets();
        return lines.map(line => {
            const parentLine = lines.find(l => l.id === (line.game_id || line.bet_id));
            return <Rows key={`bet-form-${line.id}-${isAdvantageLine(line)}`}
                bet={line}
                active={isOpened(line.color)}
                show={this.state.formIdShow === Form.betId(line) || this.isChildBetOfSelected(line, parentLine)}
                isHidden={this.state.isHidden}
                gamerNum={this.state.gamerNum}
                selectGamer={this.selectGamer}
                selectedBet={this.state.selectedBet}
                afterBet={this.props.afterBet}
                userBets={this.props.userBets}
                seo={this.props.seo}
                userBetsHidden={this.state.userBetsHidden}
                parentLine={parentLine}
            />;
        });
    }

    selectGamer = (betId, gamerNum) => {
        this.setState(this.state.selectedBet === betId && this.state.gamerNum === gamerNum
            ? {gamerNum: null, selectedBet: betId}
            : {gamerNum, selectedBet: betId});
    };

    hideOtherForms = event => {
        event.preventDefault();
        this.setState({isHidden: !this.state.isHidden});
    };

    showHideButtonText() {
        return I18n.t(this.state.isHidden ? 'show_bet_label' : 'hide_bet_label');
    }

    userBetsClick = () => {
        this.setState({userBetsHidden: !this.state.userBetsHidden});
    };

    toggleUserBets = e => {
        e.preventDefault();
        $(e.target).next().slideToggle(!this.props.userBetsHidden, this.userBetsClick);
    };

    seoText() {
        return this.props.seo
            ? I18n.t('bet_form_tournament', {game: gameName(this.props.bet.game)})
            : getGame(this.props.bet.filter).name;
    }

    render() {
        const {bet} = this.props;
        return <FormCover id={this.props.popupId} seo={this.props.seo}>

            <meta itemProp="startDate" content={timeStampToISODateTimeFormat(bet.date)}/>
            <meta itemProp="name" content={`${gameName(bet.game)} game`}/>
            <meta itemProp="description"
                content={`${lineGamer(bet, GAMER_1).nick} vs ${lineGamer(bet, GAMER_2).nick} at ${bet.tourn}`}/>
            <div itemProp="location" itemScope itemType="http://schema.org/Place">
                <meta itemProp="name" content={bet.tourn}/>
                <div itemProp="address" itemScope itemType="http://schema.org/PostalAddress">
                    <meta itemProp="name" content={bet.tourn}/>
                </div>
            </div>
            <div itemProp="competitor" itemScope itemType="http://schema.org/SportsTeam">
                <meta itemProp="name" content={lineGamer(bet, GAMER_1).nick}/>
            </div>
            <div itemProp="competitor" itemScope itemType="http://schema.org/SportsTeam">
                <meta itemProp="name" content={lineGamer(bet, GAMER_2).nick}/>
            </div>

            <div className="match-details__heading">
                <div className="match-details__title">
                    <div className="match-details__time">
                        <span>{date(this.props.bet.date)}</span><span>{time(this.props.bet.date)}</span></div>
                    <div className="match-details__game">
                        <span>
                            {this.props.bet.filter === 0
                                ? this.props.bet.game
                                : this.seoText()}
                        </span>
                        <span>{this.props.bet.tourn}</span>
                        {this.props.bet.live
                            ? <div className="match-details__time"><span>{I18n.t('live_label')}</span></div>
                            : null}
                    </div>
                </div>
                <div className="match-details__vs">
                    {this.renderGamer(GAMER_1)}
                    <div className="match-details__vs-col2">VS
                        <div className="match-details__score">{this.renderScores()}
                            <div className="match-details__score-descr"/>
                        </div>
                    </div>
                    {this.renderGamer(GAMER_2)}
                </div>
            </div>
            <div className="match-details__item">
                {this.props.bet.text
                    ? <div
                        className="match-details__item-descr"
                        dangerouslySetInnerHTML={{__html: this.props.bet.text}}
                    />
                    : null}
            </div>
            {this.renderForms()}

            <div className="match-details__link">
                {hasNestedLines(this.props.bet)
                    ? <a href="#" onClick={this.hideOtherForms}>
                        {this.showHideButtonText()}
                    </a>
                    : null}
            </div>
        </FormCover>;
    }
}
