import React, {Component, ReactNode} from 'react';
import I18n from 'app/i18n';
import {UserStore} from 'app/store/UserStore';
import {inject, observer} from 'mobx-react';
import SmartSelectItem from 'app/components/reusable/SmartSelectItem';
import CollectionInterface from 'app/interfaces/CollectionInterface';
import {UserBetsStore} from 'app/store/UserBetsStore';
import GoBackButton from 'app/components/reusable/GoBackButton';

interface MyBetsFiltersProps {
    active_bets_only?: boolean
    playmoneyNotAvailable?: boolean
    skinsNotAvailable?: boolean
    userStore?: UserStore
    userBetsStore?: UserBetsStore
}

const PRODUCT_FILTERS_TYPES: CollectionInterface[] = [{text: 'discipline_esport', value: 'all'}, {text: 'casino', value: 'casino'}];

const BETS_FILTERS_TYPES: CollectionInterface[] = [
    {text: 'live_and_prematch', value: 'all'},
    {text: 'single_bets', value: 'simple_bets'},
    {text: 'user_menu_express', value: 'expresses'},
    {text: 'user_menu_live', value: 'live_bets'}
];

const BETS_FILTERS_TYPE_SUGGESTIONS: CollectionInterface[] = [{text: 'suggestions', value: 'suggestions'}];

const BET_TYPE_FILTERS_TYPES: CollectionInterface[] = [
    {text: 'bet_type_filters.all', value: 'all'},
    {text: 'bet_type_filters.active', value: 'active'},
    {text: 'bet_type_filters.won', value: 'won'},
    {text: 'bet_type_filters.lost', value: 'lost'},
    {text: 'bet_type_filters.canceled', value: 'canceled'}
];

const CURRENCY_FILTERS_TYPES: CollectionInterface[] = [
    {text: 'all', value: 'all'},
    {text: 'bet_real_money_label', value: 'money'},
    {text: 'bet_play_money_label', value: 'playmoney'},
    {text: 'skins', value: 'skins'}
];

const CASINO_FILTERS_TYPES: CollectionInterface[] = [
    {text: 'all', value: 'all'},
    {text: 'casino_filters.bet', value: 'bet'},
    {text: 'casino_filters.win', value: 'win'},
    {text: 'casino_filters.rollback', value: 'rollback'}
];

const CASINO_TYPE = 'casino';

@inject('userStore', 'userBetsStore')
@observer
export default class MyBetsFilters extends Component<MyBetsFiltersProps> {
    componentWillUnmount(): void {
        this.props.userBetsStore.setDefaultAllFilters();
    }

    changeType = (value: string): void => {
        const {userBetsStore} = this.props;
        userBetsStore.setDefaultParams();
        userBetsStore.changeType(value, true);
        userBetsStore.loadBets(userBetsStore.filter.type);
    };

    changeProductType = (value: string): void => {
        const {userBetsStore} = this.props;
        userBetsStore.setDefaultParams();
        userBetsStore.changeProductType(value);
        userBetsStore.loadBets(userBetsStore.filter.product_type, true);
    };

    changeCasinoAction = (value: string): void => {
        this.props.userBetsStore.loadLogs(value);
    };

    isCasino = (): boolean => this.props.userBetsStore.filter.product_type === CASINO_TYPE;

    render(): ReactNode {
        const {userBetsStore, userStore: {user}} = this.props;
        //* *** Remove after release Incubator2
        const betsFilters = user.enable_incubator2
            ? BETS_FILTERS_TYPES.concat(BETS_FILTERS_TYPE_SUGGESTIONS)
            : BETS_FILTERS_TYPES;
        return <>
            <div className="top-filters">
                <div className="top-filters__title">
                    <GoBackButton/>
                    <h1 className="title">{I18n.t('bet_history')}</h1>
                </div>
                <div className="top-filters__body">
                    <div className="top-filters-row">
                        <label className="top-filters-row__title">{I18n.t('filter_product_type')}</label>
                        <SmartSelectItem
                            onChange={this.changeProductType}
                            value={userBetsStore.filter.product_type}
                            collection={PRODUCT_FILTERS_TYPES}
                            nameAttr="select-bet-product-type"
                            htmlId="history_bet_product_type"
                        />
                    </div>
                    <div className="top-filters-row">
                        <label className="top-filters-row__title">
                            {this.isCasino() ? I18n.t('action') : I18n.t('filter_type_bet')}
                        </label>
                        {this.isCasino()
                            ? <SmartSelectItem
                                onChange={this.changeCasinoAction}
                                value={userBetsStore.casinoFilter.action}
                                collection={CASINO_FILTERS_TYPES}
                                nameAttr="select-bet-type-c"
                                htmlId="history_casino_bet_type"
                            />
                            : <SmartSelectItem
                                onChange={this.changeType}
                                value={userBetsStore.filter.type}
                                collection={betsFilters}
                                nameAttr="select-bet-type"
                                htmlId="history_bet_type"
                            />}
                    </div>
                    <div className={`top-filters-row${this.isCasino() ? ' top-filters-row_disabled' : ''}`}>
                        <label className="top-filters-row__title">{I18n.t('filter_type_status')}</label>
                        <SmartSelectItem
                            onChange={userBetsStore.changeBetType}
                            value={userBetsStore.filter.bet_type}
                            collection={BET_TYPE_FILTERS_TYPES}
                            nameAttr="select-bet-status"
                            htmlId="history_bet_status"
                        />
                    </div>
                    <div className={`top-filters-row${this.isCasino() ? ' top-filters-row_disabled' : ''}`}>
                        <label className="top-filters-row__title">{I18n.t('filter_type_currency')}</label>
                        {<SmartSelectItem
                            onChange={userBetsStore.changeBetCurrency}
                            value={userBetsStore.filter.bet_currency}
                            collection={CURRENCY_FILTERS_TYPES}
                            nameAttr="select-bet-currency"
                            htmlId="history_bet_currency"
                        />}
                    </div>
                </div>
            </div>
        </>;
    }
}

