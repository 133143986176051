import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {inject, observer} from 'mobx-react';
import MyBetsFilters from 'site_version/common/MyBetsFilters';
import TableHistory from 'app/components/my_profile/table-history/TableHistory';
import TableHistoryExpress from 'app/components/my_profile/table-history/TableHistoryExpress';
import {CasinoLogs} from 'app/components/my_bets/CasinoLogs';
import {UserBetsStore} from 'app/store/UserBetsStore';
import {UserStore} from 'app/store/UserStore';

interface Props {
    userBetsStore?: UserBetsStore
    userStore?: UserStore
}

const MyBets = (props: Props): JSX.Element => {
    const location = useLocation();
    const {userBetsStore} = props;

    useEffect(() => {
        const {state} = location;
        userBetsStore.setDefaultParams();
        if (state && state.type) {
            userBetsStore.changeType(state.type);
        }

        if (state && state.bet_type) {
            userBetsStore.changeBetType(state.bet_type);
        }
        loadBets();
    }, [location.state]);

    const loadBets = () => {
        userBetsStore.loadBets(userBetsStore.filter.type);
    };

    const renderTable = () => {
        if (userBetsStore.filter.type === 'expresses') {
            return <TableHistoryExpress hasBets={props.userStore.user.first_bet}/>;
        }

        if (userBetsStore.filter.product_type === 'casino') {
            return <CasinoLogs
                hasBets={props.userStore.user.casino_first_bet}
                logs={userBetsStore.casinoLogs}
                has_more={userBetsStore.casinoFilter.has_more}
            />;
        }

        return <TableHistory hasBets={props.userStore.user.first_bet} reloadBets={loadBets}/>;
    };

    return (
        <>
            <MyBetsFilters/>
            {renderTable()}
        </>
    );
};

export default inject('userStore', 'userBetsStore')(observer(MyBets));
